<template>
	<div class="home mx-8 w-auto">
		<small class="text-xl font-mono font-bold text-opacity-40">
			Showing Articles with "{{ tag }}" tag
		</small>
		<div class="my-4 md:grid md:grid-cols-8 md:gap-8">
			<div class="md:col-span-1"></div>
			<div class="md:col-span-4">
				<ArticleBlockLoading v-show="!articlesLoaded" />
				<ArticleBlockLoaded
					v-for="article in articles"
					v-show="articlesLoaded"
					v-cloak
					:article="article"
					:key="article._id"
				/>
			</div>
			<div class="mt-8 md:col-span-2">
				<div class="block">
					<h4 class="block text-lg uppercase font-bold mt-4">
						Categories
					</h4>
					<!-- TODO: add categories component </ul> -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
//packages import
import axios from "axios";

//components import
import ArticleBlockLoading from "./../components/ArticleBlockLoading.vue";
import ArticleBlockLoaded from "./../components/ArticleBlock.vue";

export default {
	name: "Tag",
	components: { ArticleBlockLoading, ArticleBlockLoaded },
	data() {
		return { articles: [], articlesLoaded: false };
	},
	props: { tag: { required: true, type: String } },
	mounted() {
		this.fetchArticlesByMatchingTag();
	},
	methods: {
		fetchArticlesByMatchingTag() {
			let baseUrl = "https://shaoor-api.azurewebsites.net/api/";
			let articlesByTag = `${baseUrl}tag/${this.tag}`;
			axios.get(articlesByTag).then(res => {
				this.articles = res.data;
				this.articlesLoaded = true;
			});
		},
	},
};
</script>

<style></style>
